<template lang="pug">
div.view_container(v-bind:class="{view_container_small: isSmallScreen}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  v-dialog
  div.view_title What do you want people to record?
  div.description
      span e.g. you can ask someone to say happy birthday, share a favorite memory, blow a kiss...
      span(v-show="haveAsk")
        br
        br
        span Your asks so far:
        span(v-for="(ask,index) in asks" )
          span(v-if="index!=0") ,
          a.bold(v-on:click="confirmDelete(ask.id, ask.text)")  {{ ask.text }}
        br
  div.input_container
    input(v-model="text" type="text" placeholder="Type in your ask and press enter" v-on:keyup="keyEnterToSubmit")
  br
  button.cta(v-show="haveAsk" v-on:click="next" v-bind:class="{small_screen:isSmallScreen}") Next
  button.addask(v-on:click="createAsk" v-bind:class="{small_screen:isSmallScreen}") {{ askButtonTitle }}
</template>

<script>
/* global gtag */
import config from '../appConfig'

import { CREATE_ASK_MUTATION, DELETE_ASK_MUTATION } from '../graphql/mutations'
import { GET_ASKS_QUERY } from '../graphql/queries'

import Header from '../components/Header'

export default {
  name: 'AddAsk',
  props: {
  },
  components: {
    Header,
  },
  computed: {
    currentProject () {
      return this.$store.state.currentProject
    },
    haveAsk () {
      return this.asks.length > 0
    },
    askButtonTitle () {
      if (this.loading) { return "Adding Ask..." }
      return this.asks.length > 0 ? "Save Another" : "Save Ask"
    },
  },
  data () {
    return {
      text: null,
      loading: false,
      asks: [],
      isSmallScreen: config.isSmallScreen(),
    }
  },
  apollo: {
    asks () {
      return {
        // gql query
        query: GET_ASKS_QUERY,
        // Static parameters
        variables: {
          projectId: this.currentProject.id
        },
        fetchPolicy: 'cache-and-network',
      }
    }
  },
  methods: {
    confirmDelete: function(ask_id, ask_text) {
      this.$modal.show('dialog', {
        title: 'Delete "'+ask_text+'"?',
        text:'This will permanently destroy any videos that have already been created for this ask.',
        buttons: [
          {
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes, delete',
            handler: () => {
              this.deleteAsk(ask_id)
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    deleteAsk: function(ask_id) {
      this.loading = true
      this.$apollo.mutate({
        mutation: DELETE_ASK_MUTATION,
        variables: {
          askId: ask_id
        },
      }).then((data) => {
        console.log(data)
        const success = data.data.deleteAsk.success
        const errors = data.data.deleteAsk.errors
        this.errorsToast(errors)

        if (success) {
          this.asks = this.asks.filter(function(value) {
            return value.id != ask_id
          })
          this.$toast.success("Successfully deleted ask")
        }
        this.loading = false
      }).catch((error) => {
        this.errorToast(error)
        this.loading = false
      })
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    next: function() {
      this.$router.push({
        name:"AddVideoContainer",
        params: {
          next: "/invite"
        }
      })
    },
    createAsk: async function() {
      if (!this.text) { this.$toast.error('Type in an ask') ; return }
      this.loading = true
      this.$apollo.mutate({
        mutation: CREATE_ASK_MUTATION,
        variables: {
          input: {
            projectId: this.currentProject.id,
            text: this.text
          }
        },
      }).then((data) => {
        console.log(data)
        this.text = null
        const newAsk = data.data.createAsk.ask
        const errors = data.data.createAsk.errors
        this.errorsToast(errors)

        if (newAsk) {
          this.asks.push(newAsk)
          this.$toast.success("Successfully added: "+newAsk.text+"!")
        }

        this.loading = false

      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
        this.loading = false
      })
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.createAsk()
      }
    },
  },
  mounted: function () {
    // window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/add_ask'})
  }
}
</script>

<style scoped>
#create_ask {
  text-align: center;
  position: absolute;
  top: 120px;
  left: 0px;
  width: 100%;
  z-index: 1000;
}

.bold {
  font-weight: bold;
  color: #6a7279;
}

.description {
  font-family: gibsonregular;
  font-size: 20px;
  color: #918F99;
}

button.cta {
  font-size: 20px;
}

.addask {
  font-size: 20px;
  margin: 0px 0px 0px 20px;
  padding: 20px 44px;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
}

.view_container_small button {
  width: 90%;
  max-width: 360px;
  margin: 0px 0px 20px 0px;
}
</style>
